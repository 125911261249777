import { Cancel } from "@mui/icons-material"
import { ButtonBase, IconButton, styled, Typography } from "@mui/material"
import type React from "react"
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
  type Dispatch,
  type SetStateAction,
} from "react"
import { splitIntoChunks, trackEvent } from "../../utils/mixpanel"
import { extraColors } from "../../utils/theme"
import type { AskHistoryEntry, RagResponse } from "./askDB"
import { AskResult } from "./AskResult"
import { useAskHistory, useIsAsking } from "./useAskApi"

const ASK_CONTAINER_QUERY_WIDTH = 680

const Content = styled("div")(({ theme }) => ({
  width: "100%",
  display: "grid",
  gridTemplateColumns: "1fr",
  gap: 24,
  height: "100%",
  overflow: "hidden",
  [theme.containerQueries.up(ASK_CONTAINER_QUERY_WIDTH)]: {
    gridTemplateColumns: "280px 1fr",
  },
}))

const HistoryContainer = styled("div")(() => ({
  width: "100%",
  overflow: "auto",
}))

const HistoryEntryButton = styled("div", {
  shouldForwardProp: prop => prop !== "selected",
})<{ selected: boolean }>(({ selected }) => ({
  overflow: "hidden",
  width: "100%",
  display: "flex",
  alignContent: "space-between",
  height: "30px",
  background: selected
    ? `linear-gradient(to bottom, ${extraColors.purpleSubtle}, ${extraColors.subtle})`
    : extraColors.subtle,
  textAlign: "left",
  fontFamily: "inherit",
  ["&:hover"]: {
    background: extraColors.purpleSubtle,
  },
  // show close button on hover
  "&:hover svg": {
    display: "block",
  },
  position: "relative",
}))

const HistoryEntryText = styled(ButtonBase)(({ theme }) => ({
  ...theme.typography.small1,
  padding: "6px 3px 6px 12px",
  display: "inline",
  textAlign: "left",
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
}))

const CloseButton = styled(IconButton)(() => ({
  right: 0,
  top: 0,
  padding: 4,
  marginLeft: "auto",
  "& svg": {
    display: "none",
    height: 16,
    width: 16,
  },
}))

const ResultContainerQuery = styled("div")(({ theme }) => ({
  position: "relative",
  top: -8,
  [theme.containerQueries.up(ASK_CONTAINER_QUERY_WIDTH)]: {
    display: "none",
  },
}))

const DesktopContainerQuery = styled("div")(({ theme }) => ({
  display: "none",
  [theme.containerQueries.up(ASK_CONTAINER_QUERY_WIDTH)]: {
    display: "block",
    height: "100%",
    overflow: "auto",
  },
}))

const HistoryEntryContainer = styled("div")({
  display: "grid",
  gap: 6,
  marginBottom: 12,
})

export const AskHistory = ({
  pendingAsk,
}: {
  pendingAsk?: AskHistoryEntry
}) => {
  const { askHistory } = useAskHistory()
  const [selectedHistoryISO, setSelectedHistoryISO] = useState<string>()
  const selectedHistory = useMemo(
    () =>
      (selectedHistoryISO &&
        askHistory.find(h => h.date === selectedHistoryISO)) ||
      pendingAsk ||
      // default to most recent query
      askHistory.at(-1),
    [askHistory, pendingAsk, selectedHistoryISO]
  )
  const sortedByDate = useMemo(() => {
    const entries = pendingAsk
      ? [pendingAsk, ...askHistory.toReversed()]
      : askHistory.toReversed()
    const historyByDayMap = Object.groupBy(entries, a =>
      new Date(a.date).toLocaleDateString()
    )
    return Object.entries(historyByDayMap).sort(
      ([a], [b]) => new Date(b).getTime() - new Date(a).getTime()
    )
  }, [askHistory, pendingAsk])

  return (
    <Content>
      <HistoryContainer>
        <Typography
          variant="body2Bold"
          sx={{
            marginBottom: "12px",
            color: extraColors.medium,
            display: "block",
          }}
        >
          History
        </Typography>
        {sortedByDate.map(([day, entries]) => (
          <HistoryEntriesByDay
            key={day}
            day={day}
            entries={entries}
            selectedHistory={selectedHistory}
            setSelectedHistoryISO={setSelectedHistoryISO}
          />
        ))}
      </HistoryContainer>
      <DesktopContainerQuery>
        <AskResult selectedHistory={selectedHistory} />
      </DesktopContainerQuery>
    </Content>
  )
}

const HistoryEntriesByDay: React.FC<{
  day: string
  entries?: AskHistoryEntry[]
  selectedHistory?: AskHistoryEntry
  setSelectedHistoryISO: Dispatch<SetStateAction<string | undefined>>
}> = ({ day, entries, selectedHistory, setSelectedHistoryISO }) => {
  const isAsking = useIsAsking()

  useEffect(() => {
    if (isAsking) {
      setSelectedHistoryISO(undefined)
    }
  }, [isAsking, setSelectedHistoryISO])

  return (
    <HistoryEntryContainer>
      <Typography variant="small1" color={extraColors.hint} display="block">
        {day}
      </Typography>
      {entries?.map(({ date, ragResponse: result }) => (
        <HistoryEntry
          key={result?.rag_context.query_id || result?.rag_context.answer} // older items in local storage may not have a query key, so use answer if necessary for unique key
          date={date}
          result={result}
          selectedHistory={selectedHistory}
          setSelectedHistoryISO={setSelectedHistoryISO}
        />
      ))}
    </HistoryEntryContainer>
  )
}

const HistoryEntry: React.FC<{
  date: string
  result?: RagResponse
  selectedHistory?: AskHistoryEntry
  setSelectedHistoryISO: Dispatch<SetStateAction<string | undefined>>
}> = ({ date, result, selectedHistory, setSelectedHistoryISO }) => {
  const isAsking = useIsAsking()
  const { removeHistoryEntry } = useAskHistory()

  const onClickHistoryEntry = useCallback(() => {
    setSelectedHistoryISO(date)
    const chunks = splitIntoChunks(result?.rag_context.query ?? "")
    trackEvent("CLICK_ASK_HISTORY", { query: chunks })
  }, [date, result?.rag_context.query, setSelectedHistoryISO])

  return (
    <Fragment>
      <HistoryEntryButton
        selected={!isAsking && selectedHistory?.date === date}
        onClick={onClickHistoryEntry}
      >
        <HistoryEntryText>{result?.rag_context.query}</HistoryEntryText>
        <CloseButton
          disableRipple
          onClick={e => {
            e.stopPropagation()
            removeHistoryEntry(date).catch(console.error)
          }}
        >
          <Cancel fontSize="small" />
        </CloseButton>
      </HistoryEntryButton>
      {selectedHistory?.date === date && (
        <ResultContainerQuery>
          <AskResult selectedHistory={selectedHistory} />
        </ResultContainerQuery>
      )}
    </Fragment>
  )
}
