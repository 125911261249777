import { useAuth0 } from "@auth0/auth0-react"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import axios from "axios"
import { useLiveQuery } from "dexie-react-hooks"
import { useCallback, useEffect, useMemo } from "react"
import usePersistentContext from "../../hooks/usePersistentContext"
import { NAVIGATOR_URL } from "../../utils/constants"
import { AUTH0_CLIENT_ID } from "../../utils/env"
import { hashData } from "../../utils/helpers"
import type {
  AskHistoryEntry,
  RagResponse,
  TableType,
  TableUnit,
  TabularData,
  TabularDatum,
} from "./askDB"
import { askDB, TABLE_TYPES, TABLE_UNITS } from "./askDB"

export const navigatorAxios = axios.create({
  baseURL: NAVIGATOR_URL,
})

/**
 * This hook adds an auth interceptor to axios that uses the auth0 hook to get the access token
 * It must be called before any axios requests are made
 */
export const useNavigatorAuthInterceptor = () => {
  const { getAccessTokenSilently } = useAuth0()
  useEffect(() => {
    const authInterceptor = navigatorAxios.interceptors.request.use(
      async config => {
        const token = await getAccessTokenSilently()
        config.headers.Authorization = `Bearer ${token}`
        return config
      }
    )
    return () => {
      navigatorAxios.interceptors.request.eject(authInterceptor)
    }
  }, [getAccessTokenSilently])
}

export const useAskHistory = () => {
  const [localStorage, setLocalStorage] = usePersistentContext(["ask-history"])
  const askHistoryOld = useMemo(
    () => (localStorage ? (JSON.parse(localStorage) as AskHistoryEntry[]) : []),
    [localStorage]
  )
  useEffect(() => {
    if (!askHistoryOld.length) return

    const migrateHistory = async () => {
      try {
        await askDB.askHistory.bulkAdd(askHistoryOld)
        await setLocalStorage("")
        console.log("Migrated ask history to indexedDB")
      } catch (error) {
        console.error("Failed to migrate ask history:", error)
      }
    }
    console.log("Migrating ask history to indexedDB")
    migrateHistory().catch(console.error)
  }, [askHistoryOld, setLocalStorage])
  const askHistory = useLiveQuery(() => askDB.askHistory.toArray())
  const addHistory = useCallback(
    async (newResult: Omit<AskHistoryEntry, "date">) => {
      await askDB.askHistory.add({
        date: new Date().toISOString(),
        ragResponse: newResult.ragResponse,
        tabularResponse: newResult.tabularResponse,
      })
      // Remove oldest entry if history exceeds 30
      const history = await askDB.askHistory.toArray()
      const oldestDate = history[0]?.date
      if (history.length > 30 && oldestDate) {
        await askDB.askHistory.delete(oldestDate)
      }
    },
    []
  )
  const clearHistory = useCallback(async () => {
    await askDB.askHistory.clear()
  }, [])

  const removeHistoryEntry = useCallback(async (date: string) => {
    await askDB.askHistory.delete(date)
  }, [])

  return useMemo(
    () => ({
      askHistory: askHistory || [],
      addHistory,
      clearHistory,
      removeHistoryEntry,
    }),
    [askHistory, addHistory, clearHistory, removeHistoryEntry]
  )
}

const ASK_MUTATION_KEY = ["ask"]

export const useAskApi = () => {
  const { user } = useAuth0()

  const { addHistory } = useAskHistory()
  return useMutation({
    mutationKey: ASK_MUTATION_KEY,
    mutationFn: async (query: string) => {
      const hashed = user?.email
        ? await hashData(AUTH0_CLIENT_ID + user.email)
        : ""

      if (!hashed) {
        console.error("Unable to hash user ID")
      }

      const ragPromise = getRagResponse(query, hashed)
      const tabularPromise = askQueryToTabularData(query)
      const [ragResponse, tabularResponse] = await Promise.allSettled([
        ragPromise,
        tabularPromise,
      ])
      // Log issues if any promises are rejected
      if (ragResponse.status === "rejected") {
        console.error("RAG Response Error:", ragResponse.reason)
        throw ragResponse.reason
      }
      if (tabularResponse.status === "rejected") {
        console.error("Tabular Response Error:", tabularResponse.reason)
      }

      return {
        ragResponse: ragResponse.value,
        tabularResponse:
          tabularResponse.status === "fulfilled"
            ? tabularResponse.value
            : undefined,
      }
    },
    onSettled: async result => {
      if (!result) return
      await addHistory({
        ragResponse: result.ragResponse,
        tabularResponse: result.tabularResponse,
      })
    },
  })
}

export const useIsAsking = () => {
  const client = useQueryClient()
  return client.isMutating({
    mutationKey: ASK_MUTATION_KEY,
  })
}

/**
 * Navigator Fetch Calls
 */

const getRagResponse = async (query: string, user_id: string) => {
  const result = await navigatorAxios.post<RagResponse>(
    `/rag/get_rag_response/`,
    {
      query,
      query_depth: 50,
      include_chatbot: true,
      include_context: false,
      user_id,
    }
  )
  return result.data
}

const queryThreatAnnotation = async (query: string) => {
  const result = await navigatorAxios.post<{
    disease_codes?: string[]
  }>(`/rag/query_threat_annotation/`, {
    query,
  })
  return result.data.disease_codes?.join(",")
}

const queryLocationAnnotation = async (query: string) => {
  const result = await navigatorAxios.post<{
    locations?: {
      location_id?: string
      location_source_name?: string
    }[]
    message?: string
  }>(`/rag/query_location_annotation/`, {
    query,
  })
  return result.data.locations?.map(l => l.location_id).join(",")
}

const getTabularData = async ({
  diseaseCodes,
  locationIds,
}: {
  diseaseCodes: string
  locationIds: string
}) => {
  const fetchData = async (
    unit: TableUnit,
    type: TableType
  ): Promise<TabularData | undefined> => {
    const tabularResponse = await navigatorAxios<TabularDatum[]>(
      `/rag/get_tabular_data/`,
      {
        params: {
          disease_codes: diseaseCodes,
          location_ids: locationIds,
          unit,
          type,
          paginate: false,
        },
      }
    )
    const diseaseCode = tabularResponse.data[0]?.disease_code
    if (!tabularResponse.data.length || !diseaseCode) {
      return undefined
    }
    return {
      unit,
      type,
      data: tabularResponse.data,
      diseaseCode,
    }
  }

  const fetchPromises = []

  for (const unit of Object.values(TABLE_UNITS)) {
    for (const type of Object.values(TABLE_TYPES)) {
      fetchPromises.push(fetchData(unit, type))
    }
  }

  const results = await Promise.all(fetchPromises)
  const data = results.flatMap(r => r || [])
  return data
}

const askQueryToTabularData = async (query: string) => {
  const diseaseCodes = await queryThreatAnnotation(query)
  const locationIds = await queryLocationAnnotation(query)

  if (!diseaseCodes || !locationIds) {
    return []
  }

  const data = await getTabularData({
    diseaseCodes,
    locationIds,
  })
  return data
}
