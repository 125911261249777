import {
  determineSourceLayerContext,
  isAlertLevel,
  mapboxConfig,
  type AlertWithReferences,
  type SourceLayer,
} from "@phc/common"
import type { Map } from "mapbox-gl"
import { useCallback, useEffect } from "react"
import { useMap } from "react-map-gl/mapbox"
import { useMapContext } from "../../../../contexts/MapContext"
import { useCapServiceContext } from "../../../CriticalThreats/capServiceContext"
import { getAlertColor } from "../../../Shared/AlertPill"

const setAlertHighlightState = (
  map: Map | undefined,
  sourceLayer: SourceLayer | undefined,
  locationId: string | undefined,
  // default to transparent
  lineColor = "transparent"
) => {
  if (!map || !sourceLayer || !locationId) return
  map.setFeatureState(
    {
      source: sourceLayer.sourceInfo.source,
      sourceLayer: sourceLayer.id,
      id: parseInt(locationId),
    },
    { lineColor }
  )
  // highlight layers are initially hidden for performance reasons
  if (lineColor !== "transparent") {
    // show highlight layer above boundary layer
    map.moveLayer(`${sourceLayer.id}-highlight`)
    map.setLayerZoomRange(`${sourceLayer.id}-highlight`, 0, 22)
  }
}

/**
 * Sort alerts by date, then alertLevel
 */
const sortAlertsByDate = (
  a: AlertWithReferences,
  b: AlertWithReferences
): number => {
  if (!a.publishedAt && !a.updatedAt) {
    throw new Error(
      `Alert must have a publishedAt or updatedAt: ${a.title ?? ""}`
    )
  }
  if (!b.publishedAt && !b.updatedAt) {
    throw new Error(
      `Alert must have a publishedAt or updatedAt: ${b.title ?? ""}`
    )
  }

  const aDate = new Date(a.publishedAt ?? a.updatedAt ?? "")
  const bDate = new Date(b.publishedAt ?? b.updatedAt ?? "")
  // if the date is the same, sort by alertLevel
  if (aDate.getTime() === bDate.getTime()) {
    return a.alertLevel?.localeCompare(b.alertLevel ?? "") ?? 0
  }
  return aDate.getTime() - bDate.getTime()
}

/**
 * Custom hook for alert layer that will highlight any alertIds in the clusterAlertsIds array
 */
export const useAlertHighlightSelected = () => {
  const { useAlerts } = useCapServiceContext()
  const { data: alerts } = useAlerts()
  const { clusterAlertsIds, mapLoadedRef, alertFilters } = useMapContext()
  const mapRef = useMap()

  useEffect(() => {
    const map = mapRef.current?.getMap()
    if (!map) return
    alerts?.sort(sortAlertsByDate).forEach(alert => {
      if (!mapLoadedRef.current) return
      const isSelected = clusterAlertsIds.includes(alert._id)
      const isIncluded =
        isAlertLevel(alert.alertLevel) &&
        alertFilters.includes(alert.alertLevel)
      const lineColor = getAlertColor(alert.alertLevel)
      alert.geotags?.forEach(geotag => {
        const sourceLayer = mapboxConfig.sourceLayer.find(
          layer =>
            layer.sourceInfo.source === geotag.countryCode &&
            layer.context === determineSourceLayerContext(geotag.locationId)
        )
        setAlertHighlightState(
          map,
          sourceLayer,
          geotag.locationId,
          isSelected && isIncluded ? lineColor?.border : undefined
        )
      })
    })
  }, [alertFilters, alerts, clusterAlertsIds, mapLoadedRef, mapRef])
}

/**
 * Custom hook used to highlight alerts on hover
 */
export const useMarkerHighlightHover = (alertIds: string | string[]) => {
  const { useAlerts } = useCapServiceContext()
  const { data: alerts } = useAlerts()
  const { clusterAlertsIds, mapLoadedRef } = useMapContext()
  const mapRef = useMap()

  const setHoverFeatureState = useCallback(
    (isHighlight: boolean) => {
      const isLoaded = mapLoadedRef
      const alertIdsArray = Array.isArray(alertIds) ? alertIds : [alertIds]
      const hoveredAlerts = alerts?.filter(alert =>
        alertIdsArray.includes(alert._id)
      )
      hoveredAlerts?.sort(sortAlertsByDate).forEach(alert => {
        if (!isLoaded.current) return
        // don't mess with highlight if alert is selected
        if (clusterAlertsIds.includes(alert._id)) return
        const map = mapRef.current?.getMap()
        const lineColor = getAlertColor(alert.alertLevel)?.border
        alert.geotags?.forEach(geotag => {
          const context = determineSourceLayerContext(geotag.locationId)
          const sourceLayer = mapboxConfig.sourceLayer.find(
            l =>
              l.id.includes(geotag.countryCode ?? "") && l.context === context
          )
          setAlertHighlightState(
            map,
            sourceLayer,
            geotag.locationId,
            isHighlight ? lineColor : undefined
          )
        })
      })
    },
    [alertIds, alerts, clusterAlertsIds, mapLoadedRef, mapRef]
  )
  // remove hover highlight on unmount
  // happens while a user zooms in or out
  useEffect(() => {
    const isLoaded = mapLoadedRef.current
    return () => {
      if (!isLoaded) return
      setHoverFeatureState(false)
    }
  }, [mapLoadedRef, setHoverFeatureState])

  return { setHoverFeatureState }
}
