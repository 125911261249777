import { Link, Typography } from "@mui/material"
import { useMemo } from "react"
import { extraColors } from "../../utils/theme"
import { AskLabel } from "./AskResult"

export const AskSources = ({
  sources,
  sups,
}: {
  /** @deprecated */
  sources?: HTMLLIElement[]
  sups?: HTMLElement[]
}) => {
  const content = useMemo(() => {
    if (!sources?.length && !sups?.length) return null
    if (sups?.length) {
      // dedupe sups by textContent
      const dedupedSups = sups
        .reduce<HTMLElement[]>((acc, sup) => {
          if (!acc.find(s => s.textContent === sup.textContent)) {
            acc.push(sup)
          }
          return acc
        }, [])
        .sort((a, b) => {
          const aText = a.textContent || ""
          const bText = b.textContent || ""
          return aText.localeCompare(bText)
        })
      return dedupedSups.map(sup => {
        const link = sup.querySelector("a")
        return (
          link && (
            <SourceItem
              key={link.href}
              link={link}
              title={link.textContent || ""}
              domain={link.hostname || ""}
            />
          )
        )
      })
    } else {
      // deprecated, remove when sups are fully supported
      return sources?.map((source, index) => {
        const link = source.querySelector("a")

        return (
          link && (
            <SourceItem
              key={index}
              link={link}
              title={link.textContent || ""}
              domain={link.hostname || ""}
            />
          )
        )
      })
    }
  }, [sources, sups])
  return (
    <div>
      <AskLabel component="h3">Sources</AskLabel>
      <ol style={{ margin: 0, paddingLeft: 20 }}>{content}</ol>
    </div>
  )
}

interface SourceItemProps {
  link: HTMLAnchorElement
  title: string
  domain: string
}

const SourceItem = ({ link, title, domain }: SourceItemProps) => {
  return (
    <Typography
      variant="small1"
      component="li"
      m={0}
      color={extraColors.purpleMiddle}
    >
      <Link
        variant="inherit"
        color="inherit"
        style={{
          textDecoration: "none",
          wordBreak: "break-word",
        }}
        href={link.href}
        title={link.href}
        target="_blank"
        rel="noopener noreferrer"
      >
        {domain} - {title}
      </Link>
    </Typography>
  )
}
